.profileDetails {
  display: flex;
  padding: 2rem;

  @media (max-width: 700px) {
    flex-direction: column;
    gap: 1rem;
  }

  &__box {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &__header {
      margin-bottom: 0;
      font-size: 2rem;
      font-weight: 600;
      line-height: normal;
      color: black;
    }

    &__text {
      margin-bottom: 0;
      font-size: 1rem;
      font-weight: 400;
      line-height: normal;
      color: black;
    }
  }

  &__button {
    &__edit {
      color: #fff;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      background: #2a2a2a;
      border-radius: 20px;
      padding: 10px 26px;
      outline: none;
      border: none;
      width: 10rem;

      @media (max-width: 400px) {
        font-size: 0.9rem;
      }

      a {
        color: #fff;
      }

      //   @include media('<=phone') {
      //     font-size: 12px;
      //     padding: 7px 20px;
      //   }
    }
  }
}
