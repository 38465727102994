.headline__layout {
  border: 1px solid #c2cbd1;
  width: 100%;
  height: 100%;
  border-radius: 25px;
  overflow: hidden;
  background-color: #ffffff;

  &--profile {
    margin-bottom: 41px;
  }
}

.score_text {
  font-size: 11px;
  font-weight: 400;
  color: #0f0f0f;
  margin: 0;
  padding: 0;
}

.headline__image {
  height: 17rem;
  display: block;
  object-fit: cover;
}

.content__section {
  padding: 1.5em;
}

.header__blog__text {
  font-weight: 700;
  font-size: 20px;
  color: #0f0f0f;
  line-height: 25px;
}

.news__source {
  display: flex;
  flex-direction: row;
  gap: 6px;
}
.headline__info__text {
  font-size: 12px;
  font-weight: 300;
  color: #0f0f0f;
}

.headline__source__text {
  font-size: 10px;
  font-weight: 300;
  color: #0f0f0f;
  text-decoration: underline;
}

.Headline__details {
  font-size: 12px;
  font-weight: 300;
  color: #0f0f0f;
}
.thumbs_section {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
}
.reaction__section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 6px;
}

.reaction__section__likes {
  display: flex;
  align-self: center;
}

.reaction__score {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.info__card__footer {
  background-color: #d8d8d8;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  padding: 0.5em;
}
.headline__reaction__main__text {
  font-size: 10px;
  font-weight: 500;
  margin: 0;
  padding: 0;
  color: #000000;
}
.headline__reaction__sub__text {
  font-size: 10px;
  font-weight: 200;
  margin: 0;
  padding: 0;
  color: #000000;
  cursor: pointer;
}
.text__alignment {
  display: flex;
  align-self: center;
}

.shareSection {
  margin-top: 2rem;
  padding-left: 1.5em;
  display: flex;
  gap: 1rem;
  &__image {
    align-self: flex-start;
  }
  &__details {
    display: flex;
    flex-direction: column;
    // gap: 0.5rem;

    &__text {
      margin-bottom: 0 !important;
      color: #000000;
    }
  }

  &__date {
    &__text {
      color: #000000;
    }
  }
}

.infoFile {
  background: #e9e9e9;
  border-radius: 10px;
  width: 100%;
  display: flex;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;

  &__details {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;

    &__box {
      display: flex;
      gap: 1rem;
      align-items: center;
    }

    &__text {
      color: #000000;
      font-size: 0.9rem;
      margin-bottom: 0 !important;
    }
  }
}
