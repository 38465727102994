.firstColumnAd {
  width: 100%;
  // height: 100%;
  box-shadow: 0px 0px 10px 0px rgba(145, 107, 107, 0.1);
  border: #000000 1px solid;
  border-radius: 5px;
  background-color: #183e4b;

  &__Image {
    object-fit: cover;
    width: 100%;
    height: auto;
  }

  &__text {
    font-weight: 400;
    font-size: 18px;
    color: #000000;
  }
}
