.bookmarkButton {
  display: flex;
  justify-content: center;
  align-items: center;
  &__loadMore {
    margin-top: 3rem;
    color: #fff;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    background: #2a2a2a;
    border-radius: 20px;
    padding: 10px 26px;
    outline: none;
    border: none;

    @include media('<=phone') {
      font-size: 16px;
      padding: 7px 20px;
    }
  }
}
