@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

.main {
  display: flex;
  //   justify-content: space-between;
  align-items: center;
  gap: 20px;
  position: relative;
  height: 100%;

  &__container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-left: 1rem;
    padding-right: calc(1rem - 7px);
    padding-top: 3rem;
    padding-bottom: 1rem;
    border-radius: 10px;
    position: relative;
    min-width: 50% !important;
    max-width: 50% !important;
    height: 100%;

    &--full {
      min-width: 100% !important;
      max-width: 100% !important;
    }

    @media only screen and (max-width: 1139px) {
      min-width: 100% !important;
      max-width: 100% !important;
    }

    &.left {
      background-color: #fff79f;
    }

    &.right {
      background-color: #dabbf2;

      @media only screen and (max-width: 1139px) {
        display: none;
      }
    }

    &__contentWrapper {
      overflow-y: scroll;
      height: 100%;
      min-width: 100% !important;
      max-width: 100% !important;

      @media only screen and (max-width: 1139px) {
        width: 100% !important;
      }

      &--full {
        width: 100% !important;
      }

      &.left {
        &::-webkit-scrollbar {
          width: 7px;
        }

        &::-webkit-scrollbar-track {
          background: #fff79f;
        }

        &::-webkit-scrollbar-thumb {
          background-color: gray;
          border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb:hover {
          background-color: gray;
        }
      }

      &.right {
        &::-webkit-scrollbar {
          width: 7px;
        }

        &::-webkit-scrollbar-track {
          background: #dabbf2;
        }

        &::-webkit-scrollbar-thumb {
          background-color: gray;
          border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb:hover {
          background-color: gray;
        }
      }
    }

    &__buttonWrapper {
      display: flex;
      gap: 15px;
      position: absolute;
      z-index: 2;
      top: 8.5px;

      &__button {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;

        outline: none;
        border: none;
        border-radius: 25px;

        padding-top: 0.2rem;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-bottom: 0.2rem;
        text-transform: uppercase;
      }

      &__buttonCategory {
        background-color: #357c78;
      }

      &__buttonTopic {
        background-color: #75bdb9;
      }

      &__buttonText {
        font-family: 'Roboto', sans-serif;
        font-weight: 700;
        font-size: 12px;
        color: #ffffff;
        margin: 0;
      }

      &__icon {
        height: 17px;
        width: 17px;
      }
    }
  }
}
