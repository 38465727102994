@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

.box {
  background-color: #d9d9d9;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;

  width: 100% !important;

  &__heading {
    font-family: 'Raleway', sans-serif;
    font-weight: 800;
    font-size: 19px;
    line-height: 28.18px;
    color: #000000;
    text-transform: uppercase;
    margin-top: 15px;
  }
  &__image {
    width: 70%;
    height: auto;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #000000;

    &__name {
      font-family: 'Raleway', sans-serif;
      font-weight: 700;
      font-size: 22px;
      line-height: 37.57px;
      color: #000000;
      text-align: center;
      margin: 0;
    }

    &__username {
      font-family: 'Raleway', sans-serif;
      font-weight: 300;
      font-size: 19px;
      line-height: 28.18px;
      color: #357c78;
      text-align: center;
      padding-top: 5px;
    }

    &__location {
      font-family: 'Raleway', sans-serif;
      font-weight: 300;
      font-size: 19px;
      line-height: 28.18px;
      color: #357c78;
      text-align: center;
    }
  }

  &__interests {
    font-family: 'Raleway', sans-serif;
    font-weight: 300;
    font-size: 17px;
    line-height: 28.18px;
    color: #357c78;
    text-align: center;
    margin-top: 10px;
    width: 90%;
  }

  &__followStatus {
    font-family: 'Raleway', sans-serif;
    font-weight: 300;
    font-size: 15px;
    line-height: 28.18px;
    color: #357c78;
    text-align: center;
  }
}
