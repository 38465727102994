/*--------------------------------------------------------------
3. content
--------------------------------------------------------------*/

/*3.1 wpo-hero-slider*/
.wpo-hero-slider {
	width: 100%;
	height: 490px;
	display: flex;
	position: relative;
	z-index: 0;

	@media (max-width: 991px) {
		height: 400px;
	}

	@media (max-width: 767px) {
		height: 300px;
	}

	.swiper-slide {
		overflow: hidden;

		.thumb {
			position: absolute;
			left: 15px;
			top: 15px;
			z-index: 99;
			padding: 3px 15px;
			border-radius: 5px;
			background: #fff;
			color: $theme-primary-color;
			font-weight: 700;
		}
	}

	.swiper-container {
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
	}

	.slide-inner {
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		z-index: 1;
		background-size: cover;
		background-position: center;
		text-align: left;
	}

	.slide-inner:before {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: #070707;
		content: "";
		opacity: 0.25;
	}

	.slide-inner .slide-content {
		position: absolute;
		left: 0;
		bottom: 0;
		padding-left: 30px;

		@media(max-width:767px){
             padding-left: 10px;
             padding-right: 10px;
			 padding-bottom: 30px;
		}

		h2{
			a{
				color: $white;

				&:hover{
					color: $theme-primary-color;
				}
			}
		}
	}

	.swiper-button-prev,
	.swiper-button-next{
      display: none;
	}

	.swiper-pagination{
		text-align: right;
		padding-right: 20px;
		padding-bottom: 20px;
	}

	.swiper-pagination-bullet {
		width: 8px;
		height: 8px;
		text-align: center;
		line-height: 8px;
		font-size: 8px;
		color: #000;
		opacity: 1;
		background: rgba(255, 255, 255, 0.2);
		position: relative;
		margin: 0 10px!important;
	}

	.swiper-pagination-bullet:before {
		position: absolute;
		left: -6px;
		top: -6px;
		width: 20px;
		height: 20px;
		border: 1px solid rgba(255, 255, 255, 0.2);
		content: "";
		border-radius: 50%;
	}

	.swiper-pagination-bullet-active {
		color: #fff;
		background: #fff;
		width: 8px;
		height: 8px;
		text-align: center;
		line-height: 8px;
	}

	.swiper-pagination-bullet-active:before {
		border: 1px solid white;
	}

	.swiper-container-horizontal>.swiper-pagination-bullets,
	.swiper-pagination-custom,
	.swiper-pagination-fraction {
		bottom: 20px;
		right: 20px;
		left: auto;
		text-align: right;
	}

	.swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
		margin: 0 10px;
	}

	@media (max-width: 1199px) {
		height: 450px;
	}

	@media (max-width: 767px) {
		height: 350px;
	}

	.gradient-overlay {
		background: linear-gradient(left, #333 45%, transparent 65%);
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		z-index: 10;
		opacity: 0.6;
	}

	.gradient-overlay+.container {
		position: relative;
		z-index: 11;
	}

	.swiper-slide {
		position: relative;
		z-index: 11;
	}

	.wpo-hero-title-top span {
		font-size: 22px;
		color: #e4e4e4;
		font-family: "Muli";
	}

	@media (max-width: 767px) {
		.wpo-hero-title-top span {
			font-size: 15px;
		}
	}

	.slide-title {
		max-width: 712px;
	}

	@media (max-width: 1199px) {
		.slide-title {
			max-width: 555px;
		}
	}

	.slide-title h2 {
		font-size: 30px;
		font-weight: 900;
		line-height: 40px;
		margin: 10px 0 15px;
		color: #fff;
	}

	@media (max-width: 1199px) {
		.slide-title h2 {
			font-size: 30px;
			font-size: 2rem;
		}
	}

	@media (max-width: 767px) {
		.slide-title h2 {
			font-size: 20px;
			font-size: 1.3333333333rem;
			line-height: 36px;
		}
	}
	@media (max-width: 575px) {
		.slide-title h2 {
			font-size: 18px;
			line-height: 26px;
		}
	}

	.slide-text {
		max-width: 680px;
	}

	@media (max-width: 767px) {
		.slide-text {
			max-width: 500px;
		}
	}

	.slide-text p {
		font-size: 22px;
		color: #fff;
		line-height: 35px;
		max-width: 680px;
		margin-bottom: 40px;
		color: #e2e2e2;
	}

	@media (max-width: 991px) {
		.slide-text p {
			font-size: 18px;
			font-size: 1.2rem;
		}
	}

	@media (max-width: 767px) {
		.slide-text p {
			font-size: 16px;
			font-size: 1.0666666667rem;
			line-height: 22px;
			margin-bottom: 30px;
		}
	}

}