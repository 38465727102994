.header__text__font {
  font-size: 1.2rem;
  font-weight: 500;
  margin: 0;
  color: #000000;
  cursor: pointer;
}
.header__menu {
  border-radius: 25px;
  width: 259px;

  &--social {
    margin-left: 1rem;
  }

  &__div {
    display: flex !important;
    gap: 1rem !important;
  }

  &__drop {
    font-size: 16px;
    font-weight: 400;
    border-radius: 25px;
    padding-left: 1rem;
    color: #000000;

    a {
      text-decoration: none !important;
      color: #000000 !important;
    }
  }
}
