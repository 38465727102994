.post__details__column__layout {
  display: flex;
  flex-direction: row;
  margin: 0 2rem 2rem;
  padding: 1rem;
  justify-content: center;

  @include media('<=tablet') {
    margin: 0;
  }
}

.nav__section {
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: flex-start;
  margin-bottom: 1em;
}

.first__column__section {
  width: 400px;

  @include media('<=tablet') {
    display: none;
  }

  // @include media('>LGdesktop') {
  //   width: 450px;
  // }
}
.second__column__section {
  width: 1000px;

  @include media('<=tablet') {
    width: 100%;
  }

  // @include media('>LGdesktop') {
  //   width: 1500px;
  // }
}
