.mainColumnLayout {
  height: 100vh;
  margin-bottom: 30px;

  @include media('<=tablet') {
    margin-bottom: 0 !important;
  }

  .column__layout {
    display: flex;
    flex-direction: row;
    justify-content: center;
    // align-items: center;
    margin: 0;
    padding: 2rem;
    padding-top: 1rem;

    // padding-left: 2rem;
    width: 100%;

    @include media('<=phone') {
      padding: 1rem;
    }

    .first__column__section {
      width: 300px;
      height: 100vh;
      display: flex;
      flex-direction: column;
      gap: 20px;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 7px;
      }

      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      &::-webkit-scrollbar-thumb {
        background-color: gray;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background-color: gray;
      }

      &--settings {
        width: 18%;
      }

      @include media('<=tablet') {
        display: none;
      }
    }
    .second__column__section {
      width: 1000px;
      height: 100vh;
      // margin-bottom: 50px;
      // overflow-y: scroll;

      @include media('<=tablet') {
        width: 100%;
      }
    }

    .third__column__section {
      width: 16%;
      margin-top: 2.5rem;

      @include media('<=tablet') {
        display: none;
      }
    }
  }
}

.news__feed__nav__section {
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: space-between;
}
