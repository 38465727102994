$gray: gray; // Define the gray color variable

.spinner {
  margin: 4.8rem auto;
  width: 6.4rem;
  aspect-ratio: 1;
  border-radius: 50%;
  background: radial-gradient(farthest-side, $gray 94%, #0000) top/10px 10px
      no-repeat,
    conic-gradient(#0000 30%, $gray);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 10px), #000 0);

  /* Animation styles */
  animation-name: rotate;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  &--mini {
    width: 3.2rem;
  }
}

@keyframes rotate {
  to {
    transform: rotate(1turn);
  }
}
