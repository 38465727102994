/*--------------------------------------------------------------
1.1 Theme Reset Style
--------------------------------------------------------------*/
html {
  font-size: $base-font-size + px;
}
// :root {
//   scroll-behavior: unset;
// }
:root {
  &,
  &.green-mode {
    scroll-behavior: unset;
    --color-border: #0e7cba;
    --color-header: #2e3532;
    --color-nav-hover: #0e7cba;
    --color-scroll: #0e7cba;
    --color-underline: #0e7cba;
    --color-newsletter: linear-gradient(
      180deg,
      #0e7cba 12.21%,
      #0ebaba 52.73%,
      #7ce4b0 84.72%
    );
  }

  &.purple-mode {
    scroll-behavior: unset;
    --color-border: #70477c;
    --color-header: #70477c;
    --color-nav-hover: #70477c;
    --color-scroll: #baa7c0;
    --color-underline: #baa7c0;
    --color-newsletter: linear-gradient(
      180deg,
      #70477c 12.21%,
      #8b6a95 52.73%,
      #baa7c0 84.72%
    );
  }
}

body {
  font-family: $base-font;
  color: $body-color;
  background-color: $body-bg-color;
  font-size: 15px;
  font-size: calc-rem-value(15);
  overflow-x: hidden;
  @include media-query(767px) {
    font-size: 14px;
  }
}

p {
  font-size: 16px;
  color: $body-color;
  line-height: 1.8em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $heading-color;
  font-family: $heading-font;
}

ul {
  padding-left: 0;
  margin: 0;
  list-style: none;
}

a {
  text-decoration: none;
  @include transition-time(0.2s);
}

a:hover {
  text-decoration: none;
}

.sr-only {
  display: none !important;
  opacity: 0;
  visibility: hidden;
}

.container {
  @media (min-width: 1400px) {
    max-width: 1170px;
  }
}

/*--------------------------------------------------------------
1.2 Global Elements
--------------------------------------------------------------*/
.page-wrapper {
  position: relative;
  overflow: hidden;
}

.wow {
  visibility: hidden;
}

.fi:before {
  margin: 0;
}

.section-padding {
  padding: 60px 0;

  @include media-query(767px) {
    padding: 40px 0;
  }
}

/*** contact form error handling ***/
.contact-validation-active {
  .error-handling-messages {
    width: 100% !important;
    margin-top: 15px !important;
  }

  label.error {
    color: red;
    font-size: calc-rem-value(14);
    font-weight: normal;
    margin: 5px 0 0 0;
    text-align: left;
    display: block;
  }

  #loader {
    display: none;
    margin-top: 10px;

    i {
      font-size: 30px;
      font-size: calc-rem-value(30);
      color: $theme-primary-color;
      display: inline-block;
      -webkit-animation: rotating linear 2s infinite;
      animation: rotating linear 2s infinite;
    }
  }

  #success,
  #error {
    width: 100%;
    color: $white;
    padding: 5px 10px;
    font-size: 16px;
    text-align: center;
    display: none;

    @include media-query(767px) {
      font-size: 15px;
    }
  }

  #success {
    background-color: lighten(green, 5%);
    border-left: 5px solid green;
    margin-bottom: 5px;
  }

  #error {
    background-color: lighten(red, 5%);
    border-left: 5px solid red;
  }
}

@-webkit-keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.rotating {
  -webkit-animation: rotating 5s alternate infinite;
  animation: rotating 5s alternate infinite;
}

/*** back to top **/
.back-to-top {
  background-color: transparentize($theme-primary-color, 0.3);
  width: 45px;
  height: 45px;
  line-height: 45px;
  border: 2px solid $theme-primary-color;
  border-radius: 45px;
  text-align: center;
  display: none;
  position: fixed;
  z-index: 999;
  right: 15px;
  bottom: 15px;
  @include transition-time(0.2s);

  @include media-query(991px) {
    width: 35px;
    height: 35px;
    line-height: 35px;
  }

  &:hover {
    background-color: $theme-primary-color;
  }
}

.back-to-top i {
  font-size: 18px;
  font-size: calc-rem-value(18);
  color: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  @include center-by-translating();
}

/** for popup image ***/
.mfp-wrap {
  background-color: transparentize($black, 0.1);
  z-index: 99999;
}

.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
  opacity: 0;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.mfp-with-zoom.mfp-ready .mfp-container {
  opacity: 1;
}
.mfp-with-zoom.mfp-ready.mfp-bg {
  opacity: 0.8;
}

.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
  opacity: 0;
}

/*** for fancybox video ***/
.fancybox-overlay {
  background: rgba(0, 0, 0, 0.9);
  z-index: 9999 !important;
}

.fancybox-wrap {
  z-index: 99999 !important;
}
