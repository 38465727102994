@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

.headlineBox {
  display: flex;
  border-radius: 5px;
  overflow: hidden;
  width: 100% !important;

  &__container {
    position: relative;
  }

  &__wrapper {
    position: absolute;
    top: 10px;
    display: flex;
    gap: 10px;
    left: 10px;

    &__tag {
      font-family: 'Roboto';
      font-size: 10px;
      line-height: 18.75px;
      font-weight: 400;
      color: #ffffff;
      background-color: #869392;
      border-radius: 3px;
      padding-left: 5px;
      padding-right: 5px;
    }
  }

  &__body {
    // border: 1px solid gray;
    padding: 0;
    background-color: #ffffff;

    &__content {
      padding: 1.5rem;
      padding-bottom: 0;
    }

    &__actions {
      background-color: #ffffff;
      padding: 1rem;

      display: flex;
      justify-content: space-between;
      align-items: center;

      &__wrapper {
        display: flex;
        align-items: center;
        gap: 15px;
        cursor: pointer;

        &__box {
          display: flex;
          align-items: center;
          gap: 5px;
        }

        &__boxImage {
          width: 15px;
          height: 15px;
        }

        &__boxText {
          margin: 0;
          font-family: 'Raleway', sans-serif;
          font-size: 15px;
          line-height: 24px;
          font-weight: 300;
          color: #000000;
        }
      }
    }

    &__AIContainer {
      padding: 1.5rem;
      padding-bottom: 1rem;
      background-color: #effef7;

      &.green {
        background-color: #effef7;
      }

      // &.purple {
      //   background-color: #f6cefc;
      // }

      &__title {
        font-family: 'Raleway', sans-serif;
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        color: #000000;
      }

      &__note {
        font-family: 'Raleway', sans-serif;
        font-size: 12px;
        line-height: 20px;
        font-weight: 300;
        color: #000000;
      }

      &__content {
        font-family: 'Raleway', sans-serif;
        font-size: 15px;
        line-height: 24px;
        font-weight: 300;
        color: #000000;
        padding-top: 15px;
        margin: 0;
      }

      &__wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      &__helpWrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 2px;
      }

      &__helpImage {
        width: 15px;
        height: 15px;
      }

      &__veracityWrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
      }

      &__veracityText {
        font-family: 'Raleway', sans-serif;
        font-weight: 800;
        font-size: 10px;
        line-height: 20px;
        color: #000000;
        margin: 0;
      }

      &__veracityTextSpan {
        font-family: 'Raleway', sans-serif;
        font-weight: 400;
        font-size: 10px;
        line-height: 20px;
        color: #000000;
      }

      &__robotWrapper {
        display: flex;
        align-items: center;
        gap: 5px;
      }

      &__robotIconOne {
        width: 27px;
        height: 34px;
      }

      &__robotIconTwo {
        width: 21px;
        height: 37px;
      }

      &__robotIconThree {
        width: 17px;
        height: 45px;
      }

      &__robotIconFour {
        width: 15px;
        height: 38px;
      }
    }

    &__heading {
      font-family: 'Raleway', sans-serif;
      font-weight: 300;
      font-size: 19px;
      line-height: 24px;
      color: #000000;

      &:hover {
        text-decoration: underline;
      }
    }

    &__details {
      font-family: 'Raleway', sans-serif;
      font-weight: 300;
      font-size: 18px;
      line-height: 24px;
      color: #000000;
      margin: 0;
    }

    &__wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 10px;
    }

    &__days {
      font-family: 'Raleway', sans-serif;
      font-weight: 300;
      font-size: 18px;
      line-height: 24px;
      color: #000000;
      margin-top: 0;
    }

    &__likeWrapper {
      display: flex;
      gap: 20px;

      &__icon {
        width: 22px;
        height: 22px;
      }
    }

    &__iconWrapper {
      display: flex;
      gap: 10px;

      &__icon {
        width: 20px;
        height: 20px;
      }

      &__text {
        font-family: 'Raleway', sans-serif;
        font-weight: 300;
        font-size: 18px;
        line-height: 24px;
        color: #000000;
      }
    }

    &__commentSection {
      border-top: 1px solid #d8d8d8;
      padding: 1.5rem;
    }

    &__form {
      display: flex;
      flex-direction: column;

      &__input {
        width: 100%;
        padding: 1rem;
        padding-bottom: 4.5rem;
        outline: none;
        border: 1px solid #d8d8d8;
        border-radius: 15px 15px 0px 0px;
        font-family: 'Raleway', sans-serif;
      }

      &__actions {
        background-color: #d8d8d8;
        height: 30px;
        border-radius: 0px 0px 15px 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 1rem;
        padding-right: 1rem;
      }

      &__button {
        padding: 5px 15px 5px 15px;
        border-radius: 20px;
        outline: none;
        display: block;
        border: none;
        background-color: #2a2a2a;
        color: #ffffff;
        font-family: 'Raleway', sans-serif;
        font-size: 10px;
        line-height: 10px;
        text-align: center;
        font-weight: 800;
      }

      &__iconWrapper {
        display: flex;
        align-items: center;
        gap: 7px;
      }

      &__icon {
        color: #000000;
      }
    }

    &__commentActions {
      display: flex;
      padding-top: 1.5rem;
      gap: 30px;
    }

    &__commentActionsWrapper {
      display: flex;
      align-items: center;
      gap: 3px;
      cursor: pointer;
    }

    &__commentRelevant {
      margin: 0;
      font-family: 'Raleway', sans-serif;
      font-weight: 800;
      font-size: 14px;
      line-height: 20px;
      color: #000000;
    }

    &__commentRelevantIcon {
      color: #000000;
      width: 19px;
      height: 19px;
    }

    &__commentShowMore {
      margin: 0;
      font-family: 'Raleway', sans-serif;
      font-weight: 800;
      font-size: 14px;
      line-height: 20px;
      color: #000000;
    }

    &__commentBox {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__commentHeader {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-top: 20px;

      &__avatar {
        width: 40px;
        height: 40px;
      }

      &__username {
        margin: 0;
        font-family: 'Raleway', sans-serif;
        font-weight: 800;
        font-size: 14px;
        line-height: 20px;
        color: #000000;
      }

      &__interval {
        margin: 0;
        font-family: 'Raleway', sans-serif;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #000000;
      }
    }

    &__commentMoreDots {
      color: #000000;
      width: 25px;
      height: 25px;
      margin-top: 20px;
      cursor: pointer;
    }

    &__commentBodyText {
      margin: 0;
      font-family: 'Raleway', sans-serif;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #000000;
      padding-left: 50px;
    }

    &__commentDetails {
      display: flex;
      padding-left: 50px;
      align-items: center;
      gap: 20px;
      padding-top: 10px;

      &__wrapper {
        display: flex;
        align-items: center;
        gap: 5px;
      }

      &__image {
        width: 15px;
        height: 15px;
      }

      &__text {
        margin: 0;
        font-family: 'Raleway', sans-serif;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #000000;
      }
    }
  }
}

.noPostsText {
  margin: 0;
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  color: #000000;
}
