/*------------------------------------
	Info widget
------------------------------------*/
.info__widget__box {
  @include media('<=tablet') {
    display: none;
  }
}

.info__widget_layout {
  border: 1px solid #c2cbd1;
  width: 100%;
  border-radius: 25px;
  padding: 2em;
  display: flex;
  flex-direction: column;
  align-content: center;
}
.info__main__text__font {
  font-size: 1rem;
  font-weight: 500;
  color: #000000;
  padding: 0;
  margin: 0;
}
.nav__main__text__font {
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  color: #000000;
  padding: 0;
  margin: 0;
}

.info__sub__text__font {
  font-size: 1rem;
  font-weight: 200;
  color: #000000;
  padding: 0;
  margin: 0;
}
.text__alignment {
  display: flex;
  align-self: center;
}
.thumbs_section {
  display: flex;
  flex-direction: row;
  gap: 6px;
  margin-top: 10px;
}

.badge__section {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  margin-top: 5px;
}
.badge__text {
  font-size: 10px;
  color: white;
  font-weight: 400;
  padding: 0;
  margin: 0;
}
.widget__info {
  display: flex;
  gap: 0.5rem;
}
