.profileSettings {
  padding-top: 2rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-bottom: 3.5rem;
  width: 100% !important;

  &__header {
    color: #000;
    font-weight: 600;

    @media (max-width: 700px) {
      font-size: 1.8rem;
    }
  }

  &__box {
    padding-top: 2rem;
    display: flex;
    gap: 5rem;

    @media (max-width: 700px) {
      flex-direction: column;
      gap: 1rem;
    }

    &__options {
      display: flex;
      gap: 1rem;
      margin-left: 1.5rem;
      margin-top: 1rem;

      @media (max-width: 700px) {
        margin-left: 0;
      }
    }

    &__option {
      text-decoration: underline;
      color: #000;
      font-weight: 800;
    }

    &__formBox {
      width: 100%;
    }

    &__form {
      display: flex;
      flex-direction: column;
    }

    &__label {
      font-size: 1.5rem;
      font-weight: 600;
      color: #000;
      margin-bottom: 0.5rem;

      @media (max-width: 700px) {
        font-size: 1.3rem;
      }

      @media (max-width: 375px) {
        font-size: 1rem;
      }
    }

    &__input {
      margin-bottom: 1.5rem;
      border: 1px solid #a2a2a2;
      border-radius: 25px;
      padding: 0.5rem;
      padding-left: 1rem;
      outline: none;

      &--bio {
        padding-top: 1rem;
        padding-left: 1rem;
        padding-bottom: 6rem;
      }
    }

    &__input::placeholder {
      color: #a2a2a2;
    }

    &__buttonDiv {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    &__button {
      color: #fff;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
      background: #2a2a2a;
      border-radius: 20px;
      padding: 10px 26px;
      outline: none;
      border: none;

      @media (max-width: 375px) {
        font-size: 0.7rem;
      }
    }
  }
}

.profileTab {
  width: 100%;
  border-radius: 20px;
  margin-bottom: 2rem;
  border: 1px solid #c2cbd1;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__parent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &__div {
    padding: 1rem;
    display: flex;
    gap: 2.5rem;

    &__link {
      font-weight: 500;
      color: #000;

      &--active {
        font-weight: 800;
        text-decoration: underline;
      }
    }

    &__sort {
      padding-right: 1rem;
      color: #000;
    }
  }
}
