.post-nf {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 2rem;

  &--search {
    position: relative;
    height: 66vh;
  }

  &__text {
    font-size: 1.8rem;
    color: #444444;
    font-weight: bold;

    &--search {
      font-size: 2rem;
      text-align: center;

      // @include media('<=phone') {
      //   font-size: 2rem;
      // }
    }
  }

  &__back {
    display: flex;
    align-items: baseline;
    gap: 0.5rem;

    &--search {
      align-self: flex-start;
      margin-left: 1rem;
    }
  }

  &__arrow {
    width: 1rem;
    cursor: pointer;
  }

  &__backText {
    font-size: 1rem;
    color: #444444;
    cursor: pointer;
  }
}
