@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

.categoriesBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  //   margin-top: 50px;

  @media only screen and (max-width: 1139px) {
    display: none;
  }

  &__item {
    &__link {
      color: #2e3532;
      font-weight: 800;
      font-size: 14px;
      line-height: 28.18px;
      font-family: 'Raleway', sans-serif;

      &--active {
        position: relative;
      }

      &--active:after {
        content: '';
        position: absolute;
        top: 15px;
        left: 0;
        right: 0;
        background: #0e7cba;
        height: 3px;
        border-radius: 25px;
      }
    }
  }
}

.topicsBar {
  //   width: 500px;
  //   position: absolute;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
  row-gap: 0;

  //   left: 318px;
  //   top: 55px;

  &__text {
    color: #2e3532;
    font-weight: 500;
    font-size: 11px;
    // line-height: 28.18px;
    font-family: 'Raleway', sans-serif;
    margin: 0;
    cursor: pointer;

    &--active {
      position: relative;
    }

    &--active:after {
      content: '';
      position: absolute;
      top: 13px;
      left: 0;
      right: 0;
      background: #0e7cba;
      height: 3px;
      border-radius: 25px;
    }
  }
}

// .newsFeedStyleText {
//   color: #2e3532;
//   font-weight: 800;
//   font-size: 11px;
//   line-height: 13px;
//   font-family: 'Raleway', sans-serif;
//   text-align: center;
//   margin: 0;
//   cursor: pointer;

// @media only screen and (max-width: 1139px) {
//   display: none;
// }
// }

.newsFeedStyleIconContainer {
  width: 100px;
  height: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media only screen and (max-width: 1139px) {
    display: none;
  }

  .newsFeedStyleIcon {
    &--mobile {
      width: 35px;
      height: 25px;
      color: #000000;
      cursor: pointer;

      @media only screen and (max-width: 1139px) {
        display: none;
      }
    }

    &--desktop {
      width: 45px;
      height: 25px;
      color: #000000;
      cursor: pointer;

      @media only screen and (max-width: 1139px) {
        display: none;
      }
    }
  }
}
