.social__icon {
    position: relative;
    width: 24px;
    height: 21px;
    cursor: pointer;
}

.socials {
    background: #E9E9E9;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 50px;
    gap: 10px;
    position: absolute;
    right: 0;
    top: 35px;
}

.socials .copy {
    background: rgba(215, 74, 73, 0.22);
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.socials .copy img {
    width: 16px;
    height: 16px;
}