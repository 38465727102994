.grid-layout {
  display: grid;
  // grid-template-columns: minmax(400px, 500px) minmax(400px, 500px);
  grid-template-columns: minmax(300px, 450px) minmax(300px, 450px);
  grid-template-rows: repeat(2, min-content);
  gap: 2rem;

  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
  }
}
