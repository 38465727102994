@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

.box {
  background-color: #d9d9d9;
  border-radius: 10px;

  width: 100% !important;

  &__heading {
    font-family: 'Raleway', sans-serif;
    font-weight: 800;
    font-size: 19px;
    line-height: 28.18px;
    color: #000000;
    text-transform: uppercase;
    text-align: center;
    padding-top: 15px;
    padding-bottom: 20px;
    border-bottom: 1px solid #000000;
    margin: 0;
  }

  &__body {
    padding-bottom: 10px;
    border-bottom: 1px solid #000000;
    padding-top: 25px;
    .box__container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 15px;
      padding-left: 15px;

      &__title {
        font-family: 'Raleway', sans-serif;
        font-weight: 700;
        font-size: 15px;
        line-height: 23.48px;
        color: #000000;
        text-transform: uppercase;
      }

      &__add {
        font-family: 'Raleway', sans-serif;
        font-weight: 300;
        font-size: 12px;
        line-height: 23.48px;
        color: #000000;
      }
    }

    .box__categories {
      // padding-right: 25px;
      padding-left: 35px;
      &__text {
        font-family: 'Raleway', sans-serif;
        font-weight: 300;
        font-size: 15px;
        line-height: 23.48px;
        color: #000000;
      }
    }
    .box__showMore {
      font-family: 'Raleway', sans-serif;
      font-weight: 300;
      font-size: 13px;
      line-height: 18.78px;
      color: #000000;
      text-align: center;
    }
  }

  &__bodyLast {
    border-bottom: none !important;
  }
}
