.comment_card__layout {
  display: flex;
  flex-direction: row;
  padding: 1.5em;
  gap: 1em;
}
.comment__details__layout {
  display: flex;
  flex-direction: column;
  gap: 1em;
}
.profile__photo_area {
  display: initial;
  justify-items: center;
  justify-content: flex-end;
}
.username__section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 2em;
}
.actions__section {
  display: flex;
  flex-direction: row;
  gap: 4px;
}
.username__area {
  display: flex;
  flex-direction: row;
  gap: 6px;
}
.username__text {
  font-weight: 600;
  font-size: 16px;
  color: #0F0F0F;
  margin: 0;
}
.post__duration__text {
  font-size: 15px;
  font-weight: 100;
  color: #0f0f0f;
  margin: 0;
}
.action_text {
  font-size: 15px;
  font-weight: 100;
  color: #0f0f0f;
  margin: 0;
  cursor: pointer;
}
.news__content {
  font-size: 14px;
  font-weight: 400;
  color: #0f0f0f;
}