.post__details__layout {
  border: 1px solid #c2cbd1;
  width: 100%;
  border-radius: 25px;
  overflow: hidden;
}

.post__content__section {
  padding: 1em;
}

.header__text {
  font-weight: 700;
  font-size: 1.7rem;
  color: #0f0f0f;
  line-height: 25px;
  padding-top: 1em;

  @include media('<=tablet') {
    font-size: 1.4rem;
  }
}

.news__source {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
}
.Post__headline__info__text {
  font-size: 1rem;
  font-weight: 300;
  color: #0f0f0f;

  @include media('<=tablet') {
    font-size: 0.8rem;
  }
}

.post__headline__source__text {
  font-size: 1rem;
  font-weight: 300;
  color: #0f0f0f;
  text-decoration: underline;

  @include media('<=tablet') {
    font-size: 0.8rem;
  }
}

.post__content__details {
  font-size: 1.2rem;
  font-weight: 300;
  color: #0f0f0f;

  @include media('<=tablet') {
    font-size: 1rem;
  }
}
.post__thumbs_section {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.post__reaction__section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  margin-top: 2rem;
}
.post__reaction__score {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}
.info__card__footer {
  background-color: #d8d8d8;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom-right-radius: 25px;
  border-bottom-left-radius: 25px;
  padding: 1.5em;
}
.post__reaction__main__text {
  font-size: 1.4rem;
  font-weight: 500;
  margin: 0;
  padding: 0;
  color: #000000;

  @include media('<=tablet') {
    font-size: 0.8rem;
  }
}
.post__reaction__sub__text {
  font-size: 1.1rem;
  font-weight: 200;
  margin: 0;
  padding: 0;
  color: #000000;

  @include media('<=tablet') {
    font-size: 0.8rem;
  }
}
.text__alignment {
  display: flex;
  align-self: center;
  cursor: pointer;
}

.comment__card {
  width: auto;
}

.info__card__footer.toggled {
  background-color: white;
}

.post__thumbs__box {
  width: 1.5rem;
  height: auto;
  cursor: pointer;

  @include media('<=tablet') {
    width: 1rem;
  }
}
